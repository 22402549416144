export const SPEAKER_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment speakerActionButtonAddnoteFragment on Speaker {
    __typename
    id
    uid
    name
    prefix
    suffix
    photoFileResource {
      schemaCode
      path
    }
  }
`;
